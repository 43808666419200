<template>
  <b-container fluid>
    <b-row v-if="masaDegistir" class="mb-1">
      <b-col cols="12" class="text-right">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            size="lg"
            class="mr-1"
            @click="masaDegistir = false,masaTasi.masaID = null,masaTasi.adisyonID = null"
        >
          <font-awesome-icon icon="xmark" class="mr-50"/>
          İptal
        </b-button>
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="lg"
            @click="tableDegistir()"
        >
          <font-awesome-icon icon="floppy-disk" class="mr-50 fa-bounce"/>
          Kaydet
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="adisyonAktar" class="mb-1">
      <b-col cols="12" class="text-right">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            size="lg"
            class="mr-1"
            @click="adisyonAktar = false,adisyonTransferData.eskiAdisyon = null,adisyonTransferData.yeniAdisyon = null"
        >
          <font-awesome-icon icon="xmark" class="mr-50"/>
          İptal
        </b-button>
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="lg"
            @click="adisyonTransfer()"
        >
          <font-awesome-icon icon="floppy-disk" class="mr-50 fa-bounce"/>
          Aktar
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-button variant="relief-success" class="float-right hazir-btn"
                  size="sm"
                  :class="isHazirSiparisler ? 'hazir-siparis-btn-active' : 'hazir-siparis-btn'"
                  @click="hazirSiparisClick()"
        >
          Hazırlanan Siparişler ( {{ hazirSiparisler.length }} )
        </b-button>
        <b-button :disabled="spinnerDashData" variant="relief-success" class="float-right hazir-btn mr-1" size="sm"
                  @click="dashBoardData()"
        >
          <b-spinner v-if="spinnerDashData" small class="mr-50"/>
          Yenile
        </b-button>
      </b-col>
    </b-row>
    <b-row class="salon-and-masa-list">
      <b-col :lg="!isHazirSiparisler ? 12 : 9" md="12" sm="12" cols="12">
        <b-tabs v-model="activeTab" no-fade class="dash-tabs">
          <hr class="m-0">
          <b-tab v-for="item in dashData" :key="item.salonID" :title="item.salonAdi">
            <b-row>
              <b-col v-for="masa in item.masaListesi" :key="masa.masaID"
                     class="p-0 mt-lg-2 mt-md-2 mt-sm-2 mt-2"
              >
                <b-card-actions
                    v-if="(!adisyonAktar || (adisyonAktar && masa.adisyonBilgileri)) && !masaDegistir || (masaDegistir && !masa.adisyonBilgileri && masa.durum!=='0')"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    class="siparis-masa h-100 adisyon-table"
                    :class="[odemeDurum(masa) ? 'odeme-tamam':'',masa.durum==='0'? 'kapali-masa':'',masa.adisyonBilgileri && !odemeDurum(masa) ? 'odeme-bekleniyor':'']"
                    @refresh="refreshStop(`masa-${masa.masaID}`)"
                    @click="masa.durum === '1' ? clickAction(masa) : ''"
                >
                  <b-row class="adisyon-card-row">
                    <small class="font-weight-bolder d-inline-block ml-25 text-white">
                      {{ masa.masaAdi }}</small>
                    <b-button
                        v-if="!masaDegistir && !adisyonAktar"
                        v-b-modal.masa-action
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="flat-primary"
                        class="mr-25 masa-btn"
                        :class="masa.adisyonBilgileri ? 'text-white':'text-dark'"
                        @click="actionTable = {...masa}"
                        @click.stop
                    >
                      <font-awesome-icon icon="ellipsis-vertical"/>
                    </b-button>
                    <div v-if="masa.durum!=='0' && !masa.adisyonBilgileri && masaDegistir" @click.stop>
                      <b-form-checkbox
                          v-model="masaTasi.masaID"
                          :value="masa.masaID"
                          class="custom-control-warning"
                      />
                    </div>
                    <div
                        v-if="masa.durum!=='0' && masa.adisyonBilgileri && adisyonAktar && masa.adisyonBilgileri.adisyonID !== adisyonTransferData.eskiAdisyon"
                        @click.stop
                    >
                      <b-form-checkbox
                          v-model="adisyonTransferData.yeniAdisyon"
                          :value="masa.adisyonBilgileri.adisyonID"
                          class="custom-control-warning"
                      />
                    </div>
                  </b-row>
                  <b-row class="adisyon-card-tutar">
                    <small v-if="masa.adisyonBilgileri" class="font-weight-bolder m-0"
                           :class="masa.adisyonBilgileri ? 'text-white' : 'text-black'"
                           :style="{textDecoration : odemeDurum(masa)?'line-through':''}"
                    >
                      {{ Number(masa.adisyonBilgileri.toplamTutar) }}
                      <font-awesome-icon icon="turkish-lira-sign"/>
                    </small>
                    <p v-else v-show="masa.durum!=='0'" class="font-weight-bolder">
                      Boş Masa
                    </p>
                    <p v-if="masa.durum==='0'">
                      Kapalı Masa
                    </p>
                  </b-row>
                  <b-row class="adisyon-card-sure">
                    <small v-if="masa.adisyonBilgileri"
                           class="font-weight-bolder pl-50"
                           :class="masa.adisyonBilgileri ? 'text-white' : 'text-black'"
                    >
                      {{ adisyonSure(masa.adisyonBilgileri.sure) }}
                    </small>
                  </b-row>
                </b-card-actions>
                <b-card-actions v-else class="siparis-masa h-100 disable-card adisyon-table"
                                :class="[odemeDurum(masa) ? 'odeme-tamam':'',masa.durum==='0'? 'kapali-masa':'',masa.adisyonBilgileri && !odemeDurum(masa) ? 'odeme-bekleniyor':'']"
                >
                  <b-row class="adisyon-card-row">
                    <small class="font-weight-bolder d-inline-block ml-25 text-white">
                      {{ masa.masaAdi }}</small>
                    <b-button
                        v-if="!masaDegistir && !adisyonAktar"
                        v-b-modal.masa-action
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="flat-primary"
                        class="mr-25 masa-btn"
                        :class="masa.adisyonBilgileri ? 'text-white':'text-dark'"
                        @click="actionTable = {...masa}"
                        @click.stop
                    >
                      <font-awesome-icon icon="ellipsis-vertical"/>
                    </b-button>
                    <div v-if="masa.durum!=='0' && !masa.adisyonBilgileri && masaDegistir" @click.stop>
                      <b-form-checkbox
                          v-model="masaTasi.masaID"
                          :value="masa.masaID"
                          class="custom-control-warning"
                      />
                    </div>
                    <div
                        v-if="masa.durum!=='0' && masa.adisyonBilgileri && adisyonAktar && masa.adisyonBilgileri.adisyonID !== adisyonTransferData.eskiAdisyon"
                        @click.stop
                    >
                      <b-form-checkbox
                          v-model="adisyonTransferData.yeniAdisyon"
                          :value="masa.adisyonBilgileri.adisyonID"
                          class="custom-control-warning"
                      />
                    </div>
                  </b-row>
                  <b-row class="adisyon-card-tutar">
                    <small v-if="masa.adisyonBilgileri" class="font-weight-bolder m-0"
                           :class="masa.adisyonBilgileri ? 'text-white' : 'text-black'"
                           :style="{textDecoration : odemeDurum(masa)?'line-through':''}"
                    >
                      {{ Number(masa.adisyonBilgileri.toplamTutar) }}
                      <font-awesome-icon icon="turkish-lira-sign"/>
                    </small>
                    <p v-else v-show="masa.durum!=='0'" class="font-weight-bolder">
                      Boş Masa
                    </p>
                    <p v-if="masa.durum==='0'">
                      Kapalı Masa
                    </p>
                  </b-row>
                  <b-row class="adisyon-card-sure">
                    <small v-if="masa.adisyonBilgileri"
                           class="font-weight-bolder pl-50"
                           :class="masa.adisyonBilgileri ? 'text-white' : 'text-black'"
                    >
                      {{ adisyonSure(masa.adisyonBilgileri.sure) }}
                    </small>
                  </b-row>
                </b-card-actions>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-col>
      <b-col v-if="isHazirSiparisler" lg="3" md="12" sm="12" cols="12" class="mt-3">
        <b-list-group v-if="hazirSiparisler.length > 0">
          <b-list-group-item v-for="item in hazirSiparisler" :key="item.adisyonID">
            <b-row>
              <b-col xl="8" lg="12" md="12" cols="12" class="d-flex place-center">
                <div class="d-inline-grid">
                  <strong>{{ item.masaAdi }}</strong> <strong>{{ item.userName }} </strong>
                </div>
                <div class="d-inline-grid">
                  <strong class="ml-1">{{ item.tutar }}
                    <font-awesome-icon icon="turkish-lira-sign"/>
                  </strong>
                  <strong :key="gecenSureCount" :class="!item.isExpired ? 'text-primary' :'text-danger'"
                          class="text-center ml-1"
                  >{{ calculateElapsedTime(item.sure) }}</strong>
                </div>
              </b-col>
              <b-col xl="4" lg="12" md="12" cols="12" class="text-center">
                <div class="hazir-siparis-seperated"/>
                <b-button variant="relief-success" class="float-xl-right"
                          :to="`/order/${item.masaID}/${item.adisyonID}`"
                >Detay
                </b-button>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
        <h4 v-else class="text-center font-weight-bolder">Hazır Sipariş Bulunmuyor</h4>
      </b-col>
    </b-row>
    <b-modal
        id="masa-action"
        ok-only
        ok-variant="success"
        modal-class="modal-primary"
        hide-footer
        centered
        title="Masa İşlemleri"
        no-fade
    >
      <b-row class="mb-1">
        <b-col v-if="$store.state.role === 'pc' && actionTable.adisyonBilgileri" lg="4" md="4" sm="12" cols="12"
               class="mt-1"
        >
          <div v-ripple.400="'rgb(216, 217, 218,0.15)'" class="action-card"
               @click="runPaymentScreen(actionTable.adisyonBilgileri.adisyonID,actionTable.masaAdi,1),runPaymentScreen(actionTable.adisyonBilgileri.adisyonID,actionTable.masaAdi,1,true)"
          >
            <font-awesome-icon icon="credit-card" class="mt-1 fa-xl text-success"/>
            <p class="m-0 mb-1 mt-50 font-weight-bolder">Öde</p>
          </div>
        </b-col>
        <b-col v-if="$store.state.role === 'pc' && actionTable.adisyonBilgileri" lg="4" md="4" sm="12" cols="12"
               class="mt-1"
        >
          <div v-ripple.400="'rgb(216, 217, 218,0.15)'" class="action-card"
               @click="runPaymentScreen(actionTable.adisyonBilgileri.adisyonID,actionTable.masaAdi,2),runPaymentScreen(actionTable.adisyonBilgileri.adisyonID,actionTable.masaAdi,2,true)"
          >
            <font-awesome-icon icon="square-caret-right" class="mt-1 fa-xl text-success"/>
            <p class="m-0 mb-1 mt-50 font-weight-bolder">Hızlı Öde</p>
          </div>
        </b-col>
        <b-col v-if="odemeDurum(actionTable)" lg="4" md="4" sm="12" cols="12" class="mt-1">
          <div v-ripple.400="'rgb(216, 217, 218,0.15)'" class="action-card"
               @click="orderClosed(actionTable)"
          >
            <font-awesome-icon icon="xmark" class="mt-1 fa-xl text-primary"/>
            <p class="m-0 mb-1 mt-50 font-weight-bolder">Siparişi Kapat</p>
          </div>
        </b-col>
        <b-col v-if="actionTable.adisyonBilgileri" lg="4" md="4" sm="12" cols="12" class="mt-1">
          <div v-ripple.400="'rgb(216, 217, 218,0.15)'" class="action-card" @click="adisyonSil()">
            <font-awesome-icon icon="circle-xmark" class="mt-1 fa-xl text-danger"/>
            <p class="m-0 mb-1 mt-50 font-weight-bolder">İptal Et</p>
          </div>
        </b-col>
        <b-col v-if="actionTable.adisyonBilgileri" lg="4" md="4" sm="12" cols="12" class="mt-1">
          <div v-ripple.400="'rgb(216, 217, 218,0.15)'" class="action-card"
               @click="masaDegistir = true, $bvModal.hide('masa-action'),masaTasi.adisyonID = actionTable.adisyonBilgileri.adisyonID"
          >
            <font-awesome-icon icon="diagram-successor" class="mt-1 fa-xl text-warning"/>
            <p class="m-0 mb-1 mt-50 font-weight-bolder">Masayı Değiştir</p>
          </div>
        </b-col>
        <b-col v-if="actionTable.adisyonBilgileri" lg="4" md="4" sm="12" cols="12" class="mt-1">
          <div v-ripple.400="'rgb(216, 217, 218,0.15)'" class="action-card"
               @click="adisyonTransferData.eskiAdisyon = actionTable.adisyonBilgileri.adisyonID,adisyonAktar = true,$bvModal.hide('masa-action')"
          >
            <font-awesome-icon icon="angles-right" class="mt-1 fa-xl text-primary"/>
            <p class="m-0 mb-1 mt-50 font-weight-bolder">Adisyon Aktar</p>
          </div>
        </b-col>
        <!--        <b-col v-if="actionTable.adisyonBilgileri" lg="4" md="4" sm="12" cols="12" class="mt-1">-->
        <!--          <div v-ripple.400="'rgb(216, 217, 218,0.15)'" class="action-card">-->
        <!--            <font-awesome-icon icon="print" class="mt-1 fa-xl text-secondary"/>-->
        <!--            <p class="m-0 mb-1 mt-50 font-weight-bolder">Yazdır</p>-->
        <!--          </div>-->
        <!--        </b-col>-->
        <b-col v-if="$store.state.role === 'pc'" lg="4" md="4" sm="12" cols="12" class="mt-1">
          <div v-ripple.400="'rgb(216, 217, 218,0.15)'" class="action-card" v-b-modal.masa-durumu
               @click="$bvModal.hide('masa-action')"
          >
            <font-awesome-icon icon="gear" class="mt-1 fa-xl text-secondary"/>
            <p class="m-0 mb-1 mt-50 font-weight-bolder">Masa Durumu</p>
          </div>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
        id="masa-durumu"
        ok-only
        ok-variant="success"
        modal-class="modal-primary"
        ok-title="Kaydet"
        centered
        title="Masa Durumu"
        no-fade
        @ok="tableStatusChange()"
    >
      <b-row>
        <b-col lg="6" md="6" sm="12" cols="12" class="mt-1">
          <div v-ripple.400="'rgb(216, 217, 218,0.15)'" class="action-card"
               :class="actionTable.durum === '1' ? 'table-status-check':''"
               @click="actionTable.durum  = '1'"
          >
            <font-awesome-icon icon="check" class="mt-1 fa-xl text-success"/>
            <p class="m-0 mb-1 mt-50 font-weight-bolder">Aktif</p>
          </div>
        </b-col>
        <b-col lg="6" md="6" sm="12" cols="12" class="mt-1">
          <div v-ripple.400="'rgb(216, 217, 218,0.15)'" class="action-card"
               :class="actionTable.durum === '0' ? 'table-status-check':''"
               @click="actionTable.durum = '0'"
          >
            <font-awesome-icon icon="xmark" class="mt-1 fa-xl text-danger"/>
            <p class="m-0 mb-1 mt-50 font-weight-bolder">Pasif</p>
          </div>
        </b-col>
      </b-row>
    </b-modal>
    <ode v-if="isPayment" :order-data="paymentData.data"
         :adisyonID="paymentData.data.adisyonID"
         :masa-adi="paymentData.masaAdi"
         @finished="paymentFinished"
    />
    <hizli-ode v-if="isFastPayment" :order-data="paymentData.data" :adisyonID="paymentData.data.adisyonID"
               :masa-adi="paymentData.masaAdi" @finished="paymentFinished"
    />
  </b-container>
</template>

<script>
import {
  BSpinner,
  BTabs,
  BTab,
  BButton,
  BRow,
  BCol,
  BModal,
  BListGroup,
  BListGroupItem,
  BFormCheckbox,
  VBTooltip,
  BContainer,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import store from '@/store'
import router from '@/router'
import axios from 'axios'
import { useWindowSize } from '@vueuse/core'
import Ode from '@/views/siparis/odeme/ode.vue'
import HizliOde from '@/views/siparis/odeme/hizliOde.vue'
import moment from 'moment/moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
  components: {
    BSpinner,
    HizliOde,
    Ode,
    BCardActions,
    BTabs,
    BTab,
    BButton,
    BRow,
    BCol,
    BModal,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BContainer,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      spinnerDashData: true,
      gecenSureCount: 0,
      isHazirSiparisler: false,
      adisyonTransferData: {
        eskiAdisyon: null,
        yeniAdisyon: null,
      },
      adisyonAktar: false,
      masaTasi: {
        masaID: null,
        adisyonID: null,
      },
      masaDegistir: false,
      adisyonInfo: [],
      paymentData: {
        data: [],
        masaAdi: '',
      },
      isPayment: false,
      isFastPayment: false,
      isCombine: false,
      activeTab: null,
      actionTable: [],
    }
  },
  computed: {
    hazirSiparisler: () => store.getters.GET_HAZIR_SIPARISLER || [],
    dashData: () => store.getters.GET_DASH_DATA || [],
  },
  watch: {
    isHazirSiparisler(status) {
      if (status) {
        this.hazirSiparislerData()
      }
    },
  },
  created() {
    this.dashBoardData()
  },
  mounted() {
    this.hazirSiparislerData()
    this.timer = setInterval(() => {
      this.updateElapsedTimes()
    }, 1000)
    setInterval(() => {
      this.dashBoardData()
      this.hazirSiparislerData()
    }, 90000)
  },
  beforeDestroy() {
    // store.commit({
    //   type: 'SET_DASH_DATA',
    //   action: 0,
    // })
    store.commit({
      type: 'SET_HAZIR_SIPARISLER',
      action: 0,
    })
  },
  methods: {
    hazirSiparisClick() {
      this.isHazirSiparisler = !this.isHazirSiparisler
      if (this.isHazirSiparisler && this.width < 992) {
        document.getElementsByTagName('html')[0].style.scrollBehavior = 'smooth'
        window.scrollTo(0, document.body.scrollHeight)
      }
    },
    updateElapsedTimes() {
      const currentTime = moment()
      Object.values(this.hazirSiparisler)
          .forEach(order => order.isExpired = currentTime.diff(moment(order.sure, 'YYYY-MM-DD HH:mm:ss'), 'minutes') >= 20)
      this.gecenSureCount++
    },
    calculateElapsedTime(insertTime) {
      const startTime = moment(insertTime, 'YYYY-MM-DD HH:mm:ss')
      const now = moment()
      const duration = moment.duration(now.diff(startTime))
      return moment.utc(duration.asMilliseconds())
          .format('HH:mm:ss')
    },
    hazirSiparislerData() {
      if (store.getters.isAuthenticated) {
        const fd = this.postSchema()
        fd.set('serviceName', 'restoran')
        fd.append('methodName', 'hazirSiparisListesi')
        axios.post('', fd)
            .then(response => store.commit({
              type: 'SET_HAZIR_SIPARISLER',
              action: 1,
              list: response.siparis,
            }))
      }
    },
    adisyonSure(sure) {
      const current = new Date()
      const date = new Date(sure)
      const timeDiff = Math.abs(current - date)
      const minutes = Math.floor(timeDiff / (1000 * 60))
      let result = ''
      if (minutes > 0) {
        result += minutes === 1 ? '1 dakika' : `${minutes} dakika`
      } else {
        result = 'yeni adisyon'
      }
      return result.trim()
    },
    adisyonTransfer() {
      const fd = this.postSchema()
      fd.set('serviceName', 'adisyon')
      fd.append('methodName', 'masaAktar')
      fd.append('eskiAdisyon', this.adisyonTransferData.eskiAdisyon)
      fd.append('yeniAdisyon', this.adisyonTransferData.yeniAdisyon)
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              this.adisyonAktar = false
              this.adisyonTransferData.eskiAdisyon = null
              this.adisyonTransferData.yeniAdisyon = null
              this.dashBoardData()
            }
          })
    },
    tableDegistir() {
      if (this.masaTasi.masaID) {
        const fd = this.postSchema()
        fd.set('serviceName', 'adisyon')
        fd.append('methodName', 'masaDegistir')
        fd.append('adisyonID', this.masaTasi.adisyonID)
        fd.append('masaID', this.masaTasi.masaID)
        axios.post('', fd)
            .then(response => {
              if (response.result.status === 200) {
                this.masaDegistir = false
                this.masaTasi.adisyonID = null
                this.masaTasi.masaID = null
                this.dashBoardData()
              }
            })
      }
    },
    clickAction(masa) {
      if (!this.masaDegistir && !this.adisyonAktar) {
        this.goOrder(masa)
      } else if (this.masaDegistir && !masa.adisyonBilgileri) {
        this.masaTasi.masaID = masa.masaID
      } else if (this.adisyonAktar) {
        if (masa.adisyonBilgileri) {
          if (masa.adisyonBilgileri.adisyonID !== this.adisyonTransferData.eskiAdisyon) {
            this.adisyonTransferData.yeniAdisyon = masa.adisyonBilgileri.adisyonID
          }
        }
      }
    },
    runPaymentScreen(adisyonID, masaAdi, isFast, isToast = false) {
      this.adisyonInfo = {
        adisyonID,
        masaAdi,
        isFast,
      }
      if (adisyonID) {
        const fd = this.postSchema()
        fd.set('serviceName', 'adisyon')
        fd.append('methodName', 'adisyonDetay')
        fd.append('adisyonID', adisyonID)
        axios.post('', fd)
            .then(response => {
              if (response.result.status === 200) {
                this.paymentData = {
                  data: {
                    ...response.adisyonData,
                    urunList: response.adisyonData.urunListesi,
                  },
                  masaAdi,
                }
                delete this.paymentData.data.urunListesi
                this.isPayment = true
                this.isFastPayment = true
                this.$bvModal.hide('masa-action')
                if (isFast === 1) {
                  this.$bvModal.show('payment-modal')
                }
                if (isFast === 2) {
                  this.$bvModal.show('fast-payment-modal')
                }
              } else {
                if (isToast) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Hata',
                      text: 'Adisyon Bulunamadı',
                      icon: 'ThumbsDownIcon',
                      variant: 'warning',
                    },
                  })
                }
              }
            })
      }
    },
    paymentFinished(status, noClosed) {
      if (noClosed === 1) {
        this.isPayment = !this.isPayment
        this.isFastPayment = !this.isFastPayment
      } else {
        this.runPaymentScreen(this.adisyonInfo.adisyonID, this.adisyonInfo.masaAdi, this.adisyonInfo.isFast)
      }
      this.hazirSiparislerData()
      this.dashBoardData()
    },
    orderClosed(adisyon) {
      const fd = this.postSchema()
      fd.set('serviceName', 'adisyon')
      fd.append('methodName', 'adisyonKapat')
      fd.append('adisyonID', adisyon.adisyonBilgileri.adisyonID)
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              this.dashBoardData()
              this.$bvModal.hide('masa-action')
            }
          })
    },
    odemeDurum(adisyon) {
      if (adisyon.adisyonBilgileri) {
        return adisyon.adisyonBilgileri.durum === '1'
      }
      return false
    },
    adisyonSil() {
      this.$bvModal.hide('masa-action')
      this.$swal({
        title: 'Adisyon Silinecektir',
        text: 'Bu işlem geri alınamaz!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet Sil',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              const fd = this.postSchema()
              fd.set('serviceName', 'adisyon')
              fd.append('methodName', 'adisyonSil')
              fd.append('adisyonID', this.actionTable.adisyonBilgileri.adisyonID)
              axios.post('', fd)
                  .then(response => {
                    if (response.result.status === 200) {
                      this.hazirSiparisClick()
                      this.$bvModal.hide('masa-action')
                      this.actionTable = []
                      this.getData()
                    }
                  })
            }
          })
    },
    tableStatusChange() {
      const fd = this.postSchema()
      fd.set('serviceName', 'restoran')
      fd.append('methodName', 'masaStatus')
      fd.append('masaID', this.actionTable.masaID)
      fd.append('durum', this.actionTable.durum)
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              this.getData()
            }
          })
    },
    goOrder(masa) {
      let adisyonID = null
      if (masa.adisyonBilgileri) {
        adisyonID = masa.adisyonBilgileri.adisyonID
      } else {
        adisyonID = 0
      }
      router.push({
        name: 'order',
        params: {
          masaID: masa.masaID,
          adisyonID,
        },
      })
    },
    postSchema() {
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken || localStorage.getItem('restoranUserToken'))
      fd.append('serviceName', 'dashBoard')
      return fd
    },
    getData() {
      const fd = this.postSchema()
      fd.set('serviceName', 'dashBoard')
      axios.post('', fd)
          .then(response => store.commit({
            type: 'SET_DASH_DATA',
            action: 1,
            list: response.dashData,
          }))
    },
    dashBoardData() {
      this.spinnerDashData = true
      const fd = this.postSchema()
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              store.commit({
                type: 'SET_DASH_DATA',
                action: 1,
                list: response.dashData,
              })
              store.commit({
                type: 'SET_COLORS',
                colors: response.color,
              })
            }
          })
          .finally(() => this.spinnerDashData = false)
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'first-card'].showLoading = false
      }, 1000)
    },
  },
}
</script>
<style>
.siparis-masa .card-header {
  display: none !important;
}

.siparis-masa .card {
  margin-bottom: 0 !important;
}

.siparis-masa {
  display: inline-flex;
  justify-content: center;
  margin-right: 5px;
  cursor: pointer;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.siparis-masa .card-body {
  position: relative;
  width: 127px;
  height: 80px !important;
  display: inline-block;
  text-align: center;
  padding: 0;
}

.adisyon-table .card-content, .adisyon-table .card-body {
  height: 100%;
  display: flex;
  justify-content: center;
  border-radius: 5px;
}

.disable-card .card-content, .disable-card .card-body {
  height: 100%;
  display: flex;
  justify-content: center;
  border-radius: 5px;
}

.odeme-tamam .card-content, .odeme-tamam .card-body {
  background-color: #168aad;
}

.odeme-bekleniyor .card-content, .odeme-bekleniyor .card-body {
  background-color: #25a18e;
}

.adisyon-table h6, .adisyon-table h5 {
  color: white;
}

.tab-content > .active {
  display: flex !important;
}

.action-card {
  border: 1px solid #dbd5d5;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.table-status-check {
  background-color: #C1F4C5;
}

.adisyon-card-row {
  height: 20px;
  width: 100%;
  background-color: #5d66bb;
  border-radius: 3px 3px 0 0;
  justify-content: space-between;
}

.adisyon-card-tutar {
  position: absolute;
  top: 50%;
}

.adisyon-card-sure {
  position: absolute;
  top: 80%;
  left: 10%;
}

.col {
  flex-basis: 0;
  flex-grow: 0;
}

.disable-card {
  opacity: 0.5;
}

.paper {
  border-top-left-radius: 10px;
  float: right;
  display: grid;
  background-color: white;
  width: 40px;
  height: 40px;
  text-align: center;
  place-items: center;
}

.adisyon-circle {
  height: 23px;
}

.hazir-siparis-btn-active {
  background-color: #007991 !important;
}

.hazir-siparis-btn {
  background-color: #59CE8F !important;
}

.hazir-btn {
  z-index: 5;
  position: relative;
}

.dash-tabs {
  transform: translateY(-30px);
}

.first-part {
  place-items: center;
  display: inline-flex;
}

.second-part {
  display: inline-block;
  float: right;
}

.pull-up {
  transition: 400ms;
}

.hazir-siparis-first-part {
  display: grid;
  justify-content: center;
}

.masa-btn {
  padding: 0.1rem 0.7rem;
}

@media screen and (max-width: 600px) {
  .salon-and-masa-list {
    margin-top: 10%;
  }
}

@media screen and (min-width: 1200px) {
  .hazir-siparis-first-part {
    border-right: 1px solid #f5dbdb;
  }
}

.kapali-masa .card {
  background-color: #2e5d86;
  color: white;
}

.d-grid {
  display: grid;
}

.d-inline-grid {
  display: inline-grid;
}

.hazir-siparis-seperated {
  display: none;
  margin: 0.5rem 0 0.5rem 0;
  height: 2px;
  background-color: #FDBCB4;
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .hazir-siparis-seperated {
    display: block;
  }
}

</style>
